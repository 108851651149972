// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/utils.ts"
);
import.meta.hot.lastModified = "1713985513927.1184";
}
// REMIX HMR END

export const SEARCH_SINGLETON_ROOM_ID = "api";
export const SEARCH_PARTY_NAME = "search";
